import React from 'react';

class AccordionItem extends React.Component {
  state = {
    opened: false
  };

  render () {
    const {
      props: {
        text,
        title
      },
      state: {
        opened
      }
    } = this;

    return (
      <div
        {...{
          className: `accordion-item ${opened && 'accordion-item--opened'}`,
          onClick: () => {
            this.setState({opened: !opened});
          }
        }}
      >
        <div {...{className: 'accordion-item__line'}}>
          <h3 {...{className: 'accordion-item__title'}}>
            {title}
          </h3>
          <span {...{className: 'accordion-item__icon'}}/>
        </div>
        <div {...{className: 'accordion-item__inner'}}>
          <div {...{className: 'accordion-item__content'}}>
            {this.props.children ? this.props.children :
              <div className={'accordion-item__paragraph'}
                   dangerouslySetInnerHTML={{__html: text}}/>}
          </div>
        </div>
      </div>
    );
  }
}

export default AccordionItem;
