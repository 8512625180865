import React from 'react';
import Layout from '../components/layout';
import Recommended404 from '../components/whatNext/404Recommended';

const NotFoundPage = () => (
  <Layout>
    <div
      className={'bg-404-bg-mobile bg-cover bg-no-repeat min-h-screen-70 relative lg:min-h-unset lg:bg-404-bg lg:bg-cover lg:bg-center'}
      style={{marginTop: '-50px'}}>
      <div className="wrapper absolute bottom-0 text-shadow lg:max-w-1/2 lg:relative">
        <div className={'text-white lg:text-denim leading-snug text-center lg:pt-20'}>
          <h1 className={'text-4xl lg:text-6xl font-bold'}>PAGE NOT FOUND</h1>
          <p className={'text-sm lg:text-2xl'}>
            Something went wrong and we couldn't reach the page.<br/>
            We thought you will find the below articles interesting though
          </p>
        </div>
        <div className={'hidden lg:block w-full px-24 pb-6'}>
          <Recommended404/>
        </div>
      </div>
    </div>
    <div className={'lg:hidden w-full px-4 py-6 pb-6'}>
      <Recommended404/>
    </div>
  </Layout>
);

export default NotFoundPage;
