import React from 'react';
import Helmet from 'react-helmet';

const Twitter = props => (
  <Helmet>
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content={props.url}/>
    <meta property="twitter:title" content={props.seo?.title}/>
    <meta property="twitter:description" content={props.seo?.description}/>
    {props.seo?.image && <meta property="twitter:image" content={props.seo.image.url}/>}
  </Helmet>
);

export default Twitter;
