import React from 'react';
import Layout from '../components/layout';
import {metadata} from '../../config';
import Helmet from 'react-helmet';
import FfestiniogForm from '../components/ffestiniogForm';

const Ffestiniog = (props) => {

  const url = `${metadata.url}/ffestiniog`;

  return <Layout>
    <Helmet>
      <link rel="canonical" href={url}/>
    </Helmet>
    {/* <Basic seo={this.data.seo}/>
      <Twitter seo={this.data.seo} url={this.url}/>
      <Facebook seo={this.data.seo} url={this.url}/> */}
    <main className="wrapper main-data-container pt-5 font-outfit">
      <div className="text-5xl lg:text-6xl mb-6 lg:mb-8"
           style={{color: '#2FB7D9', lineHeight: '84.5%', fontWeight: 600}}>
        <img className="mb-4" src="/images/logos/age-group.svg" width={248} height={53}
             alt="age group logo"/>
        <div>Explore North Wales’s UNESCO</div>
        <div>Slate Landscape by Steam Train</div>
      </div>
      <div className="grid lg:grid-cols-2">
        <div className="col-span-1 text-2xl" style={{lineHeight: '1'}}>
          <img className="rounded-2xl mb-7 object-cover h-[319px] w-full" height={319} alt="cover image" src="https://www.datocms-assets.com/38567/1714568992-ffestiniog-railway-heads-to-blaenau-ffestiniog-chris-parry.jpeg"/>
          <p>Independent specialist rail tour operator Ffestiniog Travel marks its 50th anniversary
            in 2024 and, to celebrate, is offering Age Times readers the chance to win a break for
            two people to see where it all began in North Wales!</p>
          <p>Sit back and enjoy the stunning views of Snowdonia National Park and the dramatic
            UNESCO slate quarry landscape onboard the world-famous heritage Ffestiniog and Welsh
            Highland Railways – sister companies of Ffestiniog Travel.</p>
          <p>Enjoy a 4-day mid-week break for two people in North Wales including Gold Class seats
            (including afternoon tea/picnic hamper) onboard the Welsh Highland Railway from
            Porthmadog to Caernarfon which passes the foot of Mt Snowdon and the Mountain Spirit
            steam train on the Ffestiniog Railway, the world’s oldest narrow gauge railway, from
            Porthmadog to the slate mine town of Blaenau Ffestiniog.</p>
          <p>Half Board accommodation at the 4* Royal Sportsman Hotel, Porthmadog plus free time to
            explore with an option to visit Portmeirion independently.</p>
        </div>
        <div className="col-span-1">
          <FfestiniogForm/>
        </div>
      </div>

    </main>
  </Layout>;
};


export default Ffestiniog;
