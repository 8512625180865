import React from 'react';

function SeeMore ({category, isSub, onSeeMoreClick}) {
  return <div className="flex justify-center my-6">
    <div
      className="inline-block category-see-more-button border-teal border px-5 py-2 rounded-2xl cursor-pointer mx-auto"
      onClick={onSeeMoreClick}>
      See more
    </div>
    {/*{props.data.subCategoryArticles.nodes.length > 0 &&

        </div>}*/}
  </div>;
}

export default SeeMore;
