import React, {useEffect, useState} from 'react';
import {validateEmail} from '../../utils/articleUtil';
import {STRINGS} from '../../constants/strings';
import {URL} from '../../constants/urls';
import { navigate } from 'gatsby';
import '../../css/components/_ffestiniog-form.scss';

const FfestiniogForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [tnc, setTnC] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [railHoliday, setRailHoliday] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.emailaddress || '');
  }, []);

  const onActionClick = async (action) => {
    const name = `${firstName} ${lastName}`
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setError(null);
          setLoading(true);
          const railHolidayTag = railHoliday === 'yes' ? 'RAIL_HOLIDAY_YES' : 'RAIL_HOLIDAY_NO';
          fetch(`${URL.SERVER}/subscribe?name=${name}&email=${emailAddress}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}&tag=ffestiniog,${railHolidayTag}`)
            .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setFirstName('');
              setLastName('');
              setEmailAddress('');
              setTnC(false);
              setMarketing(false);
              setRailHoliday('');
              navigate(`/thankyou?returnUrl=https://www.agetimes.co.uk/travel/themed-holidays/ffestiniog-travel`);
            });
        }
        break;
    }
  };

  const validateForm = () => {
    if (!firstName || firstName.trim() === '') {
      setError('Please enter first name');
      return false;
    }
    if (!lastName || lastName.trim() === '') {
      setError('Please enter last name');
      return false;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      setError('Please enter valid email address');
      return false;
    }
    if (!tnc) {
      setError('Please accept Terms and Privacy Policy');
      return false;
    }
    if (!marketing) {
      setError('To participate in the prize draw we will need you consent to contact you');
      return false;
    }
    if (railHoliday === '') { 
      setError('Please select if you have taken a rail holiday');
      return false;
    }
    
    return true;
  };

  return <div className="ffestiniog-form">
    <div className="container">
      <h1>WIN! A North Wales railway holiday for 2!
        <div className="font-normal">Enter now!</div>
      </h1>
      <div className="flex text-lg underline mb-7">
        <a className="mr-10 cursor-pointer" href="/ffestiniog">More Details</a>
        <a className="cursor-pointer" href="/ffestiniog-terms" target="_blank">Terms & Conditions</a>
      </div>

      {error && <div className={'text-red'}>{error}</div>}
      <div className={'input-container'}>
        <label htmlFor="firstName">First name</label>
        <input id="firstName" type="text" placeholder="Enter your first name " value={firstName}
               onChange={(event) => {
                 setError(null);
                 setFirstName(event.target.value);
               }}/>
      </div>
      <div className={'input-container'}>
        <label htmlFor="lastName">Last name</label>
        <input id="lastName" type="text" placeholder="Enter your last name " value={lastName}
               onChange={(event) => {
                 setError(null);
                 setLastName(event.target.value);
               }}/>
      </div>
      <div className={'input-container'}>
        <label htmlFor="email">Email address</label>
        <input id="email" type="email" placeholder="Enter your email address here "
               value={emailAddress}
               onChange={(event) => {
                 setError(null);
                 setEmailAddress(event.target.value);
               }}/>
      </div>
      <div className={'input-container'}>
          <label>Have you ever taken a rail holiday?</label> 
          <div className={'radio-container'}>
          <div className={'checkbox-container'}>
            <input type="radio" id="railHolidayYes" name="railHoliday" value="yes"
                   checked={railHoliday === 'yes'} onChange={() => setRailHoliday('yes')} />
            <label htmlFor="railHolidayYes">Yes</label>
          </div>
          <div className={'radio-container'}>
            <input type="radio" id="railHolidayNo" name="railHoliday" value="no"
                   checked={railHoliday === 'no'} onChange={() => setRailHoliday('no')} />
            <label htmlFor="railHolidayNo">No</label>
            </div>
          </div>
        </div>
      <div className={'checkbox-container mt-6'}>
        <input type="checkbox" id="tnc" checked={tnc} defaultChecked={tnc} onChange={() => setTnC(!tnc)}/>
        <label htmlFor="tnc">
          I have read and accept the Privacy Policy and Terms & Conditions.
        </label>
      </div>
      <div className={'checkbox-container'}>
        <input type="checkbox" id="marketing" checked={marketing} defaultChecked={marketing}
               onChange={() => setMarketing(!marketing)}/>
        <label htmlFor="marketing">
          I’m happy for AgeGroup and Ffestiniog Travel to send me selected marketing information
          which may be of interest to me.
        </label>
      </div>
      <div className="action-container mt-3">
        <button onClick={() => {
          onActionClick('yes');
        }} type="button" className="bg-red py-4 px-12 text-white rounded-[19px]">
          SUBMIT MY ENTRY {loading && (
          <img alt="loading"
               src="/images/loading.svg"
               style={{marginLeft: '5px'}}
          />
        )}
        </button>
      </div>
    </div>
  </div>;
};

export default FfestiniogForm;
