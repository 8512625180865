import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from '../components/layout';

const Thankyou = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/travel/themed-holidays/ffestiniog-travel');
    }, 5000);

    return () => clearTimeout(timer);
  } , []);

  return (
    <Layout>
      <main className="wrapper main-data-container" data-datocms-noindex>
        <h1>Thank You!</h1>
        <p>Thank you for entering our competition. <br/> You will be redirected back to the article you were reading in 5 seconds. If you're not redirected, please <a href="https://www.agetimes.co.uk/travel/themed-holidays/ffestiniog-travel">click here</a>.</p>
      </main>
    </Layout>
  );
};

export default Thankyou;
