import React from 'react';

const createIcon = ({color, networkName, path}) => {
  return ({
    bgStyle,
    borderRadius,
    iconFillColor,
    bgFillColor,
    round,
    size,
    ...rest
  }) => {
    bgFillColor = bgFillColor || color;
    return <div style={{width: `${size}px`, height: `${size}px`}}>
      <svg viewBox="0 0 64 64" width={size} height={size} {...rest}>
        {round ? (
          <circle cx="32" cy="32" r="31" fill={bgFillColor} style={bgStyle || {}}/>
        ) : (
          <rect
            width="64"
            height="64"
            rx={borderRadius}
            ry={borderRadius}
            fill={bgFillColor}
            style={bgStyle}
          />
        )}

        <path d={path} fill={iconFillColor}/>
      </svg>
    </div>;
  };
};

export default createIcon;
